import React from 'react'

import '../components/App.css';

import Layout from '../components/layout'
import SEO from '../components/seo'
import tags from '../components/tags'

const HomePage = () => (
  <Layout>
    <SEO title="Undevelopment" keywords={tags} />
    <div className="page">
    <div class="page-content-container background-gradients"><div class="page-content blog-page-content top-padding"><h1>Undevelopment</h1></div><div class="page-content blog-page-content"><div class="blog-post-content"><p><em>Growth from the inside out. A personal development course, for devs.</em></p>
<h2>What this is:</h2>
<p>It’s a 12 week course, beginning the evening of Wednesday 18th August.</p>
<p>The intention is to take a fairly small, intimate group of devs (10 people max), through a personal growth journey.</p>
<p>The course draws from many sources: from zen retreats, to <a rel="noopener noreferrer" target="_blank" href="https://www.rationality.org/workshops/upcoming">rationality/decision making bootcamps</a>, to <a rel="noopener noreferrer" target="_blank" href="https://www.circlingeurope.com/">cutting-edge group meditation practices</a>, <a rel="noopener noreferrer" target="_blank" href="https://www.animascoaching.com/">to transformational coaching</a>, to name just a few, and of course, my own journey growing as a developer over the past 10 years.</p>
<p>This course will really be about learning ways to see our own blindspots and self-imposed limitations in life, and to become free of them. That’s why it’s called “undevelopment”. It’s as much as recognising and letting go of you own limiting patterns, as it is about creating more empowered ways of living.</p>
<p>As an example of this, let’s consider imposter syndrome, a very common experience for developers. Often, the advice given around this phenomena is to accept it as totally normal, and to keep calm and carry on (this is not bad advice).</p>
<p>However, what if it were possible to look more deeply into what imposter syndrome is, to investigate it with an open mind, and discover things about it that fundamentally alter your relationship to that experience.</p>
<p>This might not eliminate imposter syndrome (it also might), but seeing how it works might give you more agency and a greater capacity to make choices around it that you wouldn’t otherwise have.</p>
<p>This example generalises to many things, including the ordinary everyday experience of work as a dev. Turns out investigating your own life in this way is an extremely fruitful thing to do.</p>
<p>However, this course is also aimed at supporting you, with where you’re at in life, with all your biggest goals and desires. It’s important to ground that inward exploration in something real and tangible. We encourage you to dream big and to take on challenges that are out of your comfort zone. We’ll just help you to go deeper and to dismantle all of the obstacles you’re experiencing with respect to these things.</p>
<h2>What the course covers:</h2>
<p><strong>The core sessions will be focused on topics such as</strong>:</p>
<ul>
<li>Exploring and uncovering your own invisible internal limitations</li>
<li>Learning how to deeply connect in all your relationships</li>
<li>Embodiment and tapping into your full capacities and intelligence</li>
<li>Clear boundary setting and effective communication</li>
<li>Focus and priority</li>
<li>Developing agency and autonomy</li>
<li>Life purpose and vision, and doing your best work</li>
<li>How to develop your own metholodology for further growth beyond the course itself</li>
</ul>
<p><strong>where we will be tackling the following (non-exhaustive list) of challenges</strong>:</p>
<ul>
<li>Stuckness, lack of direction/vision/purpose, a sense of meaninglessness at work</li>
<li>Overwhelm in response to learning and growth</li>
<li>Self confidence and imposter syndrome</li>
<li>Feeling like you can’t be your full self in your relationships at work</li>
<li>Limiting beliefs about time/skills/ability/money</li>
<li>Anxiety, perfectionism, workaholism</li>
<li>How to make room for self care and wellbeing at work</li>
<li>Procrastination, distraction, and dissatisfaction at work</li>
<li>Feeling powerless or limited at work</li>
</ul>
<h2>The Structure:</h2>
<p>There are 10 evening sessions spread out over 12 weeks, beginning the evening of Wednesday 18th August.</p>
<p>Pre-course, you’ll be putting together a personal <strong>“bugs list”</strong> of challenges you’re currently facing, in yourself and in life, that you want support with. We'll be working on and developing this list throughout the course.</p>
<p>Between the sessions, there will be weekly assignments and small group “pod” calls, as well as a Slack channel for the course.</p>
<p>We will also be having 3 Sunday workshops, roughly once per month. Finally, there will be plenty of extra personalised support available, with office hours every other Sunday, and 2 x 1-1 coaching sessions available as you need them.</p>
<p><strong>In bullet point form</strong>:</p>
<ul>
<li>The core of the program is <strong>10 x 90 minute group sessions</strong> over a 12 week period, beginning <strong>Wednesday 18th August, 7:25pm - 8:55pm BST</strong></li>
<li>Weekly small group “pod” calls and assignments.</li>
<li>2 x 1-1 coaching sessions over the course of the program</li>
</ul>
<p><strong>And on Sundays throughout the course</strong>:</p>
<ul>
<li>3 x 3 hour intensives. The <strong>5th September, 3rd October, and 31st October</strong>.</li>
<li>Every other Sunday, starting 2nd Sunday, optional office hours and additional support if needed.</li>
</ul>
<h2>Pricing:</h2>
<p>The price for Undevelopment First Edition is <strong>£1200</strong>. Both upfront payment and payment plans are available.</p>
<p><em>Note: If you would love to do this course, but money is a blocker for you right now, then we should have a conversation about it and see if we can meet you where you’re at. I understand not everyone is always in a position invest in a course like this.</em></p>
<h2>Who made this course:</h2>
<p>I've been working on this course for almost a year now, alongside my good friend <strong>Erik Schneider</strong>, who is the director of software at Siemens Healthineers, and who will be co-leading the course (at least in part!). Erik has a blog named <a target="_blank" href="http://cubiclemonks.com/">cubicleMonks</a> where he writes about office work being an ideal training ground for growth and transformation.</p>
<h2>What you need to do if you’re interested:</h2>
<p>For those of you who are interested, would love to have a conversation to tell you more about it. It’s important to me to speak with everyone who is interested in taking the course, before they invest, as the course is highly personalised and a lot of context can only come through in a conversation.</p>
<p>To make a little easier on myself to organise conversations around this, I set up a booking calendar:</p>
<div class="clickable-cards">
            <a class="card" href="https://calendly.com/nickhme/60min">
              <div class="discovery-box box-left">
                Click here to book me in!
              </div>
            </a>
          </div></div></div></div>
    </div>
  </Layout>
)

export default HomePage
